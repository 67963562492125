<template>
  <div ref="test_container" class="evaluate-instrument-container pt-1">
    <div
      class="test-container"
      :class="{
        'test-container-on-scroll':
          window_top > 420 && window_top <= current_tab_height - 260,
      }"
    >
      <div v-if="questionTest">
        <InstrumentHeader
          :Instrument="questionTest"
          :TestForm="true"
          :allows_crud="allows_crud"
        ></InstrumentHeader>
      </div>
      <div class="p-1">
        <template v-for="question in evaluationQuestions">
          <template v-if="question.question_type == 1">
            <EvaluateRedactionQuestion
              :key="question.id"
              :Question="question"
              :student_id="student_id"
              :is_selected="question.id == selected_question"
              :period_id="period_id"
              @selected="slotSelectedQuestion"
              @updateAutomatedScore="updateAutomatedScore"
              @storedRedactionScores="storedRedactionScores"
            ></EvaluateRedactionQuestion>
          </template>
          <template v-else-if="question.question_type == 2">
            <EvaluateSelectQuestion
              :key="question.id"
              :Question="question"
              :student_id="student_id"
              :period_id="period_id"
              :is_selected="question.id == selected_question"
              @selected="slotSelectedQuestion"
              @updateAutomatedScore="updateAutomatedScore"
            ></EvaluateSelectQuestion>
          </template>
        </template>
      </div>
    </div>
    <template>
      <div
        class="question-test-score"
        :class="{
          'sticky-container':
            window_top > 420 && window_top <= current_tab_height - 20,
        }"
      >
        <strong class="border-bottom pb-1"
          >Evaluar {{ questionTest.title }}</strong
        >
        <div class="mt-3">
          <b-form-group
            label="Puntaje propuesto:"
            label-cols="4"
            label-cols-sm="8"
            class="mb-0 mx-3 py-1 text-left border-bottom"
          >
            <!-- <b-form-input
              type="number"
              size="sm"
              disabled
              :value="questionTestScore.automated_score"
            ></b-form-input> -->
            <span class="automated-score"
              >{{
                questionTestScore ? questionTestScore.automated_score : "0"
              }}
              / {{ questionTest.score }}</span
            >
          </b-form-group>
          <b-form-group
            label="Puntaje asignado:"
            label-cols="4"
            label-cols-sm="8"
            class="mb-0 mx-3 py-1 text-left border-bottom"
            v-if="institution"
          >
            <template
              v-if="
                institution.actual_period != null &&
                institution.actual_period.id == period_id
              "
            >
              <b-form-input
                id="manual-score-input"
                type="number"
                class="manual-score-input"
                :disabled="
                  !student_id ||
                  isNaN(manual_score.id) ||
                  redaction_null_scores.length > 0
                "
                v-model.number="manual_score.score"
              ></b-form-input>
              <b-tooltip
                target="manual-score-input"
                placement="left"
                variant="secondary"
                :show="manual_score_changed ? true : false"
              >
                Si desea puede modificar el puntaje asignado.
              </b-tooltip>
            </template>
            <template v-else>
              {{ manual_score.score }}
            </template>
          </b-form-group>

          <b-form-group
            label="Nota Final:"
            label-cols="4"
            label-cols-sm="8"
            class="mb-0 mx-3 py-1 text-left border-bottom"
          >
            {{ getInstrumentFinalScore() }}
          </b-form-group>
          <div class="feedback-section mx-2 mt-2 py-2" v-if="institution">
            <div class="text-left ml-2 mb-3">Retroalimentación:</div>
            <b-form-textarea
              size="sm"
              :disabled="
                !student_id ||
                isNaN(manual_score.id) ||
                (institution.actual_period != null &&
                  institution.actual_period.id != period_id)
              "
              v-model="manual_score.feedback"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
          <div
            v-if="student_id && institution"
            class="btn-container"
            :class="{
              'published-question-test-score': this.manual_score.publish,
            }"
          >
            <b-button
              class="btn-save mt-2 ml-2 px-5"
              :class="{ 'btn-save-block': this.manual_score.publish }"
              :disabled="
                !student_id ||
                isNaN(manual_score.id) ||
                (institution.actual_period != null &&
                  institution.actual_period.id != period_id)
              "
              size="sm"
              @click="
                patchQuestionTestScore({
                  score: manual_score.score,
                  feedback: manual_score.feedback,
                  is_graded: true,
                })
              "
            >
              Guardar
            </b-button>
            <b-button
              class="btn-publish-grading mt-2 mr-2 px-5"
              v-if="!manual_score.publish"
              :disabled="
                !student_id ||
                isNaN(manual_score.id) ||
                (institution.actual_period != null &&
                  institution.actual_period.id != period_id)
              "
              size="sm"
              variant="none"
              @click="
                patchQuestionTestScore({
                  score: manual_score.score,
                  feedback: manual_score.feedback,
                  is_graded: true,
                  publish: true,
                })
              "
            >
              Publicar
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";

export default {
  name: "TestContainer",
  components: {
    EvaluateRedactionQuestion: () => import("./EvaluateRedactionQuestion"),
    EvaluateSelectQuestion: () => import("./EvaluateSelectQuestion"),
    InstrumentHeader: () => import("@/components/evaluations/InstrumentHeader"),
  },
  props: {
    question_test_id: {
      type: Number,
      required: true,
    },
    student_id: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    window_top: {
      type: Number,
    },
    tab_index_tests: {
      type: Number,
    },
  },
  data() {
    return {
      selected_question: null,
      manual_score: {
        id: generateUniqueId(),
        score: this.questionTestScore
          ? this.questionTestScore.automated_score
          : null,
        feedback: "",
        is_graded: true,
        publish: true,
      },
      manual_score_changed: false,
      student_changed: 0,
      redaction_null_scores: [],
      current_tab_height: null,
    };
  },
  computed: {
    ...mapGetters({
      questionTests: names.QUESTION_TESTS,
      questionTestsScore: names.QUESTION_TESTS_SCORE,
      allQuestionOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      questions: names.QUESTIONS,
      institution: "getInstitution",
    }),
    questionTest() {
      return this.questionTests.find((x) => x.id == this.question_test_id);
    },
    questionTestScore() {
      if (this.student_id == null) return [];
      return this.questionTestsScore.find(
        (x) =>
          x.question_test == this.question_test_id &&
          x.student == this.student_id
      );
    },
    evaluationQuestions() {
      let list = this.questions.filter(
        (x) => x.question_test == this.question_test_id
      );
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
  },
  methods: {
    slotSelectedQuestion(question_id) {
      this.selected_question = question_id;
    },
    patchQuestionTestScore(item) {
      this.$restful
        .Patch(`/evaluation/manual-score/${this.manual_score.id}/`, item)
        .then((response) => {
          this.manual_score = response;
          toast(
            response.publish
              ? "Puntaje de Instrumento publicado."
              : "Puntaje de Instrumento actualizado."
          );
        });
    },
    fetchManualScores(manual_score_id) {
      if (this.student_id) {
        this.$restful
          .Get(`/evaluation/manual-score/${manual_score_id}/`)
          .then((response) => {
            this.manual_score = response;
            if (this.manual_score.score == null) {
              this.manual_score.score = this.questionTestScore.automated_score;
              if (this.student_changed == 0) {
                this.manual_score_changed = true;
                setTimeout(() => {
                  this.manual_score_changed = false;
                }, 3500);
              }
            }
          });
      }
    },
    updateAutomatedScore(manual_score) {
      if (manual_score && manual_score.score != null) {
        let i = this.redaction_null_scores
          .map((item) => item.id)
          .indexOf(manual_score.id);
        this.redaction_null_scores.splice(i, 1);
      }
      let payload = null;
      if (this.student_id && this.question_test_id) {
        payload = {
          question_test_id: this.question_test_id,
          user_id: this.student_id,
        };
      } else if (this.question_test_id) {
        payload = {
          question_test_id: this.question_test_id,
        };
      }
      this.$store
        .dispatch(names.FETCH_QUESTION_TESTS_SCORE, payload)
        .then((response) => {
          if (response.length > 0)
            if (response[0].score_and_feedback != null)
              this.fetchManualScores(response[0].score_and_feedback.id);
        });
    },
    storedRedactionScores(item) {
      if (item.score == null) {
        this.redaction_null_scores.push(item);
      }
    },
    getInstrumentFinalScore() {
      if (this.institution) {
        const appr_grade = this.institution.appr_grade;
        const exigence_percentage = this.institution.exigence_percentage / 100;
        const min_grade = this.institution.min_grade;
        const max_grade = this.institution.max_grade;
        const max_score = this.questionTest.score;
        const automated_score =
          this.questionTestScore &&
          this.questionTestScore.automated_score &&
          this.questionTestScore.automated_score > 0 &&
          this.questionTestScore.automated_score != null
            ? this.questionTestScore.automated_score
            : 0;
        const manual_score = this.manual_score.score;
        let tem_grade = min_grade;
        let obtained_score = 0;
        if (manual_score != null) {
          obtained_score = manual_score;
        } else {
          obtained_score = automated_score;
        }
        if (obtained_score < exigence_percentage * max_score) {
          tem_grade =
            ((appr_grade - min_grade) * obtained_score) /
              (exigence_percentage * max_score) +
            min_grade;
        } else {
          tem_grade =
            ((max_grade - appr_grade) *
              (obtained_score - exigence_percentage * max_score)) /
              (max_score * (1 - exigence_percentage)) +
            appr_grade;
        }
        if (tem_grade > max_grade) return parseFloat(max_grade.toFixed(1));
        else if (tem_grade < min_grade) return parseFloat(min_grade.toFixed(1));
        else return parseFloat(tem_grade.toFixed(1));
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.current_tab_height = this.$refs.test_container.clientHeight;
    }, 2500);

    // if (this.questionTestScore) this.fetchManualScores();
  },
  watch: {
    student_id() {
      this.redaction_null_scores = [];
      this.student_changed += 1;
      setTimeout(() => {
        this.student_changed = 0;
      }, 500);
      this.manual_score = {
        id: generateUniqueId(),
        score: null,
        feedback: "",
      };
      if (this.questionTestScore) {
        if (this.questionTestScore.score_and_feedback) {
          this.fetchManualScores(this.questionTestScore.score_and_feedback.id);
        }
      }
    },
    tab_index_tests() {
      setTimeout(() => {
        if (this.$refs.test_container.clientHeight > 0) {
          this.current_tab_height = this.$refs.test_container.clientHeight;
          this.$emit(
            "instrumentTabEvent",
            this.$refs.test_container.clientHeight
          );
        }
      }, 1500);
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_QUESTIONS, this.question_test_id);
    let payload = null;
    if (this.student_id && this.question_test_id) {
      payload = {
        question_test_id: this.question_test_id,
        user_id: this.student_id,
      };
    } else if (this.question_test_id) {
      payload = {
        question_test_id: this.question_test_id,
      };
    }
    this.$store
      .dispatch(names.FETCH_QUESTION_TESTS_SCORE, payload)
      .then((response) => {
        response.forEach((question_test_score) => {
          if (question_test_score.question_test == this.question_test_id) {
            this.fetchManualScores(question_test_score.score_and_feedback.id);
          }
        });
      });
  },
};
</script>

<style scoped>
.test-container {
  width: 75%;
  background-color: var(--primary-hover-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.test-container-on-scroll {
  transition: 0.2s all;
}
.evaluate-instrument-container {
  display: flex;
}
.question-test-score {
  width: 25%;
  padding-top: 3em;
  background: var(--primary-hover-color);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* transition: all .5s; */
}
.sticky-container {
  padding-top: 4em !important;
  padding-bottom: 4em;
  max-width: 23%;
  background: transparent !important;
  position: fixed;
  top: 95px;
  margin-right: 2%;
  right: 0;
  height: 55%;
  z-index: 1;
  /* transition: all .6s; */
}
.manual-score-input {
  transform: translate(-15%, -10%);
  max-width: 5em;
}
.rounded-button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: var(--secondary-color);
}
.rounded-button:hover {
  background-color: var(--primary-color);
}
.rounded-button:focus {
  border: none;
  outline: none;
  background-color: var(--primary-color);
}
.rounded-button:active {
  background-color: var(--secondary-color);
}
.col-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.automated-score {
  font-weight: bold;
}
.btn-container {
  display: flex;
}
.btn-save,
.btn-publish-grading {
  width: 48%;
}
.btn-save {
  margin-right: 0.2em;
}
.btn-publish-grading {
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
  transition: all 0.3s;
  margin-left: 0.2em;
}
.btn-publish-grading:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
}
.published-question-test-score {
  display: block !important;
}
.btn-save-block {
  width: calc(100% - 18px);
  margin-left: 0vh !important;
}
@media (min-width: 2000px) {
  .sticky-container {
    top: 8%;
    height: 36%;
  }
}
@media (max-width: 991px) {
  .btn-container {
    display: block;
  }
  .btn-save,
  .btn-publish-grading {
    padding: 0.25rem 0 !important;
    width: 85%;
    margin-inline: 0 !important;
  }
}
</style>