var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"test_container",staticClass:"evaluate-instrument-container pt-1"},[_c('div',{staticClass:"test-container",class:{
      'test-container-on-scroll':
        _vm.window_top > 420 && _vm.window_top <= _vm.current_tab_height - 260,
    }},[(_vm.questionTest)?_c('div',[_c('InstrumentHeader',{attrs:{"Instrument":_vm.questionTest,"TestForm":true,"allows_crud":_vm.allows_crud}})],1):_vm._e(),_c('div',{staticClass:"p-1"},[_vm._l((_vm.evaluationQuestions),function(question){return [(question.question_type == 1)?[_c('EvaluateRedactionQuestion',{key:question.id,attrs:{"Question":question,"student_id":_vm.student_id,"is_selected":question.id == _vm.selected_question,"period_id":_vm.period_id},on:{"selected":_vm.slotSelectedQuestion,"updateAutomatedScore":_vm.updateAutomatedScore,"storedRedactionScores":_vm.storedRedactionScores}})]:(question.question_type == 2)?[_c('EvaluateSelectQuestion',{key:question.id,attrs:{"Question":question,"student_id":_vm.student_id,"period_id":_vm.period_id,"is_selected":question.id == _vm.selected_question},on:{"selected":_vm.slotSelectedQuestion,"updateAutomatedScore":_vm.updateAutomatedScore}})]:_vm._e()]})],2)]),[_c('div',{staticClass:"question-test-score",class:{
        'sticky-container':
          _vm.window_top > 420 && _vm.window_top <= _vm.current_tab_height - 20,
      }},[_c('strong',{staticClass:"border-bottom pb-1"},[_vm._v("Evaluar "+_vm._s(_vm.questionTest.title))]),_c('div',{staticClass:"mt-3"},[_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":"Puntaje propuesto:","label-cols":"4","label-cols-sm":"8"}},[_c('span',{staticClass:"automated-score"},[_vm._v(_vm._s(_vm.questionTestScore ? _vm.questionTestScore.automated_score : "0")+" / "+_vm._s(_vm.questionTest.score))])]),(_vm.institution)?_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":"Puntaje asignado:","label-cols":"4","label-cols-sm":"8"}},[(
              _vm.institution.actual_period != null &&
              _vm.institution.actual_period.id == _vm.period_id
            )?[_c('b-form-input',{staticClass:"manual-score-input",attrs:{"id":"manual-score-input","type":"number","disabled":!_vm.student_id ||
                isNaN(_vm.manual_score.id) ||
                _vm.redaction_null_scores.length > 0},model:{value:(_vm.manual_score.score),callback:function ($$v) {_vm.$set(_vm.manual_score, "score", _vm._n($$v))},expression:"manual_score.score"}}),_c('b-tooltip',{attrs:{"target":"manual-score-input","placement":"left","variant":"secondary","show":_vm.manual_score_changed ? true : false}},[_vm._v(" Si desea puede modificar el puntaje asignado. ")])]:[_vm._v(" "+_vm._s(_vm.manual_score.score)+" ")]],2):_vm._e(),_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":"Nota Final:","label-cols":"4","label-cols-sm":"8"}},[_vm._v(" "+_vm._s(_vm.getInstrumentFinalScore())+" ")]),(_vm.institution)?_c('div',{staticClass:"feedback-section mx-2 mt-2 py-2"},[_c('div',{staticClass:"text-left ml-2 mb-3"},[_vm._v("Retroalimentación:")]),_c('b-form-textarea',{attrs:{"size":"sm","disabled":!_vm.student_id ||
              isNaN(_vm.manual_score.id) ||
              (_vm.institution.actual_period != null &&
                _vm.institution.actual_period.id != _vm.period_id),"rows":"6","max-rows":"6"},model:{value:(_vm.manual_score.feedback),callback:function ($$v) {_vm.$set(_vm.manual_score, "feedback", $$v)},expression:"manual_score.feedback"}})],1):_vm._e(),(_vm.student_id && _vm.institution)?_c('div',{staticClass:"btn-container",class:{
            'published-question-test-score': this.manual_score.publish,
          }},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 px-5",class:{ 'btn-save-block': this.manual_score.publish },attrs:{"disabled":!_vm.student_id ||
              isNaN(_vm.manual_score.id) ||
              (_vm.institution.actual_period != null &&
                _vm.institution.actual_period.id != _vm.period_id),"size":"sm"},on:{"click":function($event){return _vm.patchQuestionTestScore({
                score: _vm.manual_score.score,
                feedback: _vm.manual_score.feedback,
                is_graded: true,
              })}}},[_vm._v(" Guardar ")]),(!_vm.manual_score.publish)?_c('b-button',{staticClass:"btn-publish-grading mt-2 mr-2 px-5",attrs:{"disabled":!_vm.student_id ||
              isNaN(_vm.manual_score.id) ||
              (_vm.institution.actual_period != null &&
                _vm.institution.actual_period.id != _vm.period_id),"size":"sm","variant":"none"},on:{"click":function($event){return _vm.patchQuestionTestScore({
                score: _vm.manual_score.score,
                feedback: _vm.manual_score.feedback,
                is_graded: true,
                publish: true,
              })}}},[_vm._v(" Publicar ")]):_vm._e()],1):_vm._e()],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }